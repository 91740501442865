.treeview .list-group-item {
  cursor: pointer;
}

.treeview span:not(.badge) {
  width: 1rem;
  height: 1rem;
}

.treeview span.indent {
  margin-left: 10px;
  margin-right: 10px;
}

.treeview span.icon {
  margin-left: 10px;
  margin-right: 5px;
}
