.property-grid {
  table-layout: fixed;
}

.table-borderless {
  margin-bottom: 0px;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th {
  border-top: none;
  word-wrap: break-word;
  word-break: break-all;
}
