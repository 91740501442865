/*
 * less/style.less
 * Author: Melvin Chipimo <melvin@thezig.io>
 * Date: 17.04.2023
 */
/*
 */
.sidebar {
  background: #ededed;
  font-family: 'Open Sans', sans-serif;
  font-size: 12pt;
  overflow: hidden;
  position: relative;
  transition: all 300ms;
}

.sidebar>.sidebar-container>.sidebar-item>.sidebar-link {
  line-height: 2.5em;
}

.sidebar>.sidebar-container>.sidebar-item>.sidebar-link .sidebar-state-icon {
  line-height: 2.5em;
}

.sidebar::after {
  box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.5) inset;
  -webkit-box-shadow: 0 0 0.4em rgba(0, 0, 0, 0.5) inset;
  content: " ";
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.sidebar-container,
.sidebar-item {
  margin: 0;
  padding: 0;
}

.sidebar-container {
  list-style: none;
}

.sidebar-container .sidebar-container {
  box-shadow: 0 0.3em 0.3em -0.3em #0c0d0e inset, 0 -0.3em 0.3em -0.3em #0c0d0e inset;
  -webkit-box-shadow: 0 0.3em 0.3em -0.3em #0c0d0e inset, 0 -0.3em 0.3em -0.3em #0c0d0e inset;
  transition: all 300ms;
  -webkit-transition: all 300ms;
  background: rgba(255, 255, 255, 0.05);
}

.sidebar-container .sidebar-container .sidebar-item>.sidebar-link {
  height: 0;
  overflow: hidden;
}

.sidebar-container .sidebar-container .sidebar-link {
  padding-left: 1em;
}

.sidebar-container .sidebar-container .sidebar-container .sidebar-link {
  padding-left: 2em;
}

.sidebar-container.visible {
  padding: .5em 0;
}

.sidebar-container.visible>.sidebar-item>.sidebar-link {
  height: 2.5em;
}

.sidebar-link {
  color: #3b3b3b;
  transition: height 300ms, color 300ms, background-color 300ms;
  -webkit-transition: height 300ms, color 300ms, background-color 300ms;
  display: flex;
  justify-content: space-between;
  line-height: 2.5em;
  text-decoration: none;
  cursor: pointer;
}

.sidebar-link:hover {
  background: rgba(174, 174, 174, 0.36);
  color: #4679B2;
}

.sidebar-link.active {
  text-shadow: 0 -1px 0 rgba(86, 86, 86, 0.25);
  -webkit-text-shadow: 0 -1px 0 rgba(86, 86, 86, 0.25);
  border: none;
  border-left: 14px solid #89194A;
  background: rgba(174, 174, 174, 0.36);
  color: #4679B2;
}

.sidebar-link.active-list {
  text-shadow: 0 -1px 0 rgba(86, 86, 86, 0.25);
  -webkit-text-shadow: 0 -1px 0 rgba(86, 86, 86, 0.25);
  border: none;
  border-left: 4px solid #89194A;
  background: rgba(174, 174, 174, 0.36);
  color: #4679B2;
}

.sidebar-link.has-active-child {
  color: #F53;
}

.nested_wrapper {
  transition: all 300ms;
  -webkit-transition: all 300ms;
  height: 0px;
  overflow: hidden;
}

.nested_wrapper.open {
  background: rgba(203, 203, 203, 0.36);
  display: block;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

I.sidebar-icon {
  text-align: center;
  width: 3em;
}

I.sidebar-state-icon {
  transition: transform 300ms;
  -webkit-transition: transform 300ms;
  float: right;
  line-height: 2.5em;
  text-align: center;
  width: 3em;
}

I.sidebar-state-icon.rotate-minus-90 {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
